import { graphql } from "gatsby";
import React from "react";
import { OtherProducts } from "../OtherProducts";
import "./styles.css";

const ProfessionalSyrupsOtherProducts = ({ title, products }) => {
  products = [
    { ...products[0], href: "/classic-syrups" },
    { ...products[1], href: "/honey" },
    { ...products[2], href: "/packaged-honey" },
  ];
  return <OtherProducts title={title} products={products} />;
};

ProfessionalSyrupsOtherProducts.getLivePreviewData = ({ data, getAsset }) => ({
  professionalSyrupsOtherProducts: {
    ...data.professionalSyrupsOtherProducts,
    products: {
      ...data.professionalSyrupsOtherProducts.products,
      image: getAsset(data.professionalSyrupsProducts.products.image).url,
    },
  },
});

const professionalSyrupsOtherProductsQuery = graphql`
  fragment ProfessionalSyrupsOtherProductsFragment on MarkdownRemarkFrontmatter {
    professionalSyrupsOtherProducts {
      title
      products {
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        alt
      }
    }
  }
`;

export { ProfessionalSyrupsOtherProducts, professionalSyrupsOtherProductsQuery };
