import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { ProfessionalSyrupsAdvantages } from "../components/ProfessionalSyrupsAdvantages";
import { ProfessionalSyrupsHero } from "../components/ProfessionalSyrupsHero";
import { ProfessionalSyrupsOtherProducts } from "../components/ProfessionalSyrupsOtherProducts";

const ProfessionalSyrupsPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <ProfessionalSyrupsHero {...props.professionalSyrupsHero} />
      <ProfessionalSyrupsAdvantages formBody={layout.formBody} {...props.professionalSyrupsAdvantages} />
      <ProfessionalSyrupsOtherProducts {...props.professionalSyrupsOtherProducts} />
    </>
  );
};

ProfessionalSyrupsPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...ProfessionalSyrupsHero.getLivePreviewData(props),
  ...ProfessionalSyrupsAdvantages.getLivePreviewData(props),
  ...ProfessionalSyrupsOtherProducts.getLivePreviewData(props),
});

const ProfessionalSyrupsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout {...data.layout.frontmatter}>
      <ProfessionalSyrupsPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query ProfessionalSyrupsPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "professional-syrups" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }
        ...ProfessionalSyrupsHeroFragment
        ...ProfessionalSyrupsAdvantagesFragment
        ...ProfessionalSyrupsOtherProductsFragment
      }
    }
  }
`;

export default ProfessionalSyrupsPage;

export { pageQuery, ProfessionalSyrupsPageTemplate };
