import { graphql } from "gatsby";
import React from "react";
import { ClassicSyrupsHero } from "../ClassicSyrupsHero";
import "./styles.css";

const ProfessionalSyrupsHero = ({ background, alt, subtitle, title, syrups }) => {
  return (
    <ClassicSyrupsHero
      className="ProfessionalSyrupsHero"
      maxWidth="ProfessionalSyrupsHero__syrups"
      background={background}
      subtitle={subtitle}
      title={title}
      syrups={syrups}
      alt={alt}
    />
  );
};

ProfessionalSyrupsHero.getLivePreviewData = ({ data, getAsset }) => ({
  professionalSyrupsHero: {
    ...data.professionalSyrupsHero,
    background: getAsset(data.professionalSyrupsHero.background).url,
  },
});

const professionalSyrupsHeroQuery = graphql`
  fragment ProfessionalSyrupsHeroFragment on MarkdownRemarkFrontmatter {
    professionalSyrupsHero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      syrups
    }
  }
`;

export { ProfessionalSyrupsHero, professionalSyrupsHeroQuery };
